import {asyncActionsFactory} from '@wix/wix-events-commons-statics'
import {MemberPageState} from '../types/state'
import {Api} from '../utils/api'
import {SettingsApi} from '../utils/settings-api'

type Extra = {
  serverApi: Api | SettingsApi
}

export const createAsyncAction = asyncActionsFactory<MemberPageState, Extra>()
