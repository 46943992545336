import {RsvpStatus, EventsDates} from '@wix/events-types'
import {ControllerParams} from '@wix/yoshi-flow-editor'
import {GET_SITE_SETTINGS} from '../../../commons/actions/site-settings'
import {SiteSettings} from '../../../commons/types/state'
import {Api as BaseApi} from '../../../commons/utils/api'
import {UPDATE_COMPONENT, UPDATE_COMPONENT_DRAFT} from '../actions/component'
import {GET_EVENTS} from '../actions/events'
import {CANCEL_RSVP, DELETE_RSVP} from '../actions/rsvp'
import {GET_MY_TICKETS} from '../actions/tickets'
import {getComponentData} from './shared'

export class Api extends BaseApi {
  constructor(controllerParams: ControllerParams) {
    super(controllerParams)

    this.registrar = {
      [GET_EVENTS.NAME]: this.getEvents,
      [DELETE_RSVP.NAME]: this.deleteRsvp,
      [CANCEL_RSVP.NAME]: this.cancelRsvp,
      [GET_SITE_SETTINGS.NAME]: this.getSiteSettings,
      [UPDATE_COMPONENT_DRAFT.NAME]: this.updateComponentDraft,
      [UPDATE_COMPONENT.NAME]: this.updateComponent,
      [GET_MY_TICKETS.NAME]: this.getMyTickets,
    }
  }

  getEvents = ({
    memberId,
    statuses,
    extended,
    offset = 0,
    locale,
  }: {
    memberId: string
    statuses: wix.events.EventStatus[]
    extended: boolean
    offset: number
    locale: string
  }): Promise<{
    events: wix.events.Event[]
    orders: wix.events.ticketing.Order[]
    rsvps: wix.events.rsvp.Rsvp[]
    total: number
    dates: {events: EventsDates}
  }> => {
    return this.api.get(
      `/web/member-events?memberId=${memberId}&instance=${this.getInstance()}&extended=${extended}&offset=${offset}&locale=${locale}${statuses
        .map(status => `&status=${status}`)
        .join('')}`,
    )
  }

  getSiteSettings = (): Promise<SiteSettings> => this.api.get(`/web/site-settings?instance=${this.getInstance()}`)

  getComponentData = getComponentData(this.api)

  updateComponentDraft = (component: wix.events.editor.WebComponentConfig) => {
    return this.api.put(`/web/component/${this.compId}/draft`, {component})
  }

  updateComponent = (component: wix.events.editor.WebComponentConfig) => {
    return this.api.put(`/web/component/${this.compId}`, {component})
  }

  deleteRsvp = ({eventId, rsvpId}: {eventId: string; rsvpId: string}) =>
    this.api.delete(`/web/events/${eventId}/rsvp/${rsvpId}`).then(() => ({eventId}))

  cancelRsvp = ({eventId, rsvpId}: {eventId: string; rsvpId: string}) =>
    this.api.put(`/web/events/${eventId}/rsvp/${rsvpId}/status`, {status: RsvpStatus.NO}).then(() => ({eventId}))

  getMyTickets = (eventId: string, memberId: string) =>
    this.api.get(`/web/events/${eventId}/my-tickets?memberId=${memberId}`)
}
