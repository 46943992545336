import {AnyAction} from 'redux'
import {MembersComponentConfig} from '../../../commons/types/state'
import {getComponentData, UPDATE_SETTINGS} from '../actions/component'

const defaultState: MembersComponentConfig = {
  id: '',
  settings: {
    texts: {},
  },
} as MembersComponentConfig

export const component = (state = defaultState, action: AnyAction): MembersComponentConfig => {
  switch (action.type) {
    case getComponentData.fulfilled.toString():
      return {
        id: action.payload.component.id,
        settings: action.payload.component.config.settings,
      }
    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      }
    default:
      return state
  }
}
